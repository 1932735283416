/*-----------------------------------------------------
  GLOBAL VARIABLES
------------------------------------------------------*/
$fa-font-path: "../fonts/fontawesome" !default;
/*-----------------------------------------------------
  PLUGINS
------------------------------------------------------*/
@import "../../vendor/bootstrap/scss/bootstrap";
@import "../../vendor/aos/src/sass/aos";
@import "../../vendor/font-awesome/web-fonts-with-css/scss/fa-solid";
@import "../../vendor/font-awesome/web-fonts-with-css/scss/fa-regular";
@import "../../vendor/font-awesome/web-fonts-with-css/scss/fa-brands";
@import "../../vendor/font-awesome/web-fonts-with-css/scss/fontawesome";
@import "../fonts/iconmind/iconmind.css";
@import "../fonts/themify-icons/themify-icons.css";
@import "../../vendor/animate-sass/animate";
@import "../vendor/glyphicons/styles/glyphicons.css";
/*-----------------------------------------------------
  VARIABLES
------------------------------------------------------*/
@import "mixins/mixins";
@import "style/variables";
/*-----------------------------------------------------
  STYLE
------------------------------------------------------*/
@import "style/style";
/*-----------------------------------------------------
  CUSTOM FONTS
------------------------------------------------------*/
@import "../../vendor/font-rentalcar/scss/fontcarrental";