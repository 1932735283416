@import 'variables';

@font-face {
  font-family: 'Car Rental';
  font-style: normal;
  font-weight: 400;
  src: url('#{$cr-font-path}/rental_fonts-webfont.eot');
  src: url('#{$cr-font-path}/rental_fonts-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$cr-font-path}/rental_fonts-webfont.woff2') format('woff2'),
  url('#{$cr-font-path}/rental_fonts-webfont.woff') format('woff'),
  url('#{$cr-font-path}/rental_fonts-webfont.ttf') format('truetype'),
  url('#{$cr-font-path}/rental_fonts-webfont.svg#fontawesome') format('svg');
}

.cr {
  font-family: 'Car Rental';
  font-weight: 400;
}

@import 'mixins';
@import 'core';
@import 'larger';
@import 'fixed-width';
@import 'list';
@import 'bordered-pulled';
@import 'animated';
@import 'rotated-flipped';
@import 'stacked';
@import 'icons';
@import 'screen-reader';