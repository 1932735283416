/*
Template Name: Wrapkit
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/


/*==============================================================
 You can write your style here and overwrite our style
 ============================================================== */

//.btn-primary {
//  background-color: $themecolor;
//  border-color: darken($themecolor, 5%);
//  @include box-shadow(0,0,10px);
//  &:hover {
//    background-color: darken($themecolor, 10%);
//    border-color: lighten($themecolor, 5%);
//    box-shadow: none;
//  }
//}